import { Navbar, NavDropdown, Nav } from "react-bootstrap";

function Navigation() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Nav >
          <Nav.Link href="/">Home</Nav.Link>
          <NavDropdown title="Walks" id="collasible-nav-dropdown" className="ml-auto navbar-right">
            <NavDropdown.Item href="/join">Join a Walk</NavDropdown.Item>
            <NavDropdown.Item href="/listWalks">List of Walks</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/lead">Lead a Walk</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    </>
  );
}
export default Navigation;
