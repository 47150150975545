import React, { useState } from "react";
//import { useForm } from "react-hook-form";
import "../style/form.scss";
import { Row, Col, Form, Button } from "react-bootstrap";
import Geocode from "react-geocode";
import axios from "axios";

function LeadForm() {
  const initFormData = Object.freeze({
    walkInfo: "test",
    walkAddress: "246 Kloppenburg Way",
    walkNearestBusStop: "test",
    walkDate: "",
    walkDescription: "",
    walkDifficulty: "Easy",
    walkLeaderEmail: "",
    walkDuration: 0.25,
    walkMeetingPlace: "",
    hours: "",
    pointLat: null,
    pointLong: null,
    minutes: "30",
    walkLeaderName: "",
    leadePphone: "",
    walkTime: "",
    pointText: "",
  });
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState(initFormData);

  const handleChange = (e) => {
    setData({
      ...data,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
      Geocode.setApiKey(process.env.REACT_APP_GEO_KEY);
      // set response language. Defaults to english.
      Geocode.setLanguage("en");
      // set response region. Its optional.
      Geocode.setRegion("ca");
      Geocode.setLocationType("APPROXIMATE");
      Geocode.enableDebug();
      // Get latitude & longitude from address.

      Geocode.fromAddress(data.walkAddress).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          var tempData = data;
          //set lat/long
          tempData["pointLat"] = lat;
          tempData["pointLong"] = lng;
          //calculate duration to numeric
          var duration = parseInt(tempData["hours"]) + tempData["minutes"] / 60;
          tempData["walkDuration"] = duration;
          setData(tempData);

          submitData(data);
          setSubmitted(true);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    setValidated(true);
  };

  return (
    <>{submitted 
      ?<Row><Col><p>Thanks for your submission</p></Col></Row>
      :
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h1>Lead a Walk</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Address"
              required
              name="walkAddress"
              className="field"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid address.
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              required
              className="field"
              name="pointText"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid Title.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Meeting Place</Form.Label>
            <Form.Control
              type="text"
              placeholder="Meeting Place"
              required
              name="walkMeetingPlace"
              className="field"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid address.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Description</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Description"
              required
              name="walkDescription"
              className="field"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid description.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Date"
              required
              className="field"
              name="walkDate"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid date.
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Label>Time</Form.Label>
            <Form.Control
              type="time"
              placeholder="Time"
              required
              name="walkTime"
              className="field"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid time.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Duration</Form.Label>
          </Col>
          <Col>
            <Form.Control
              as="select"
              placeholder="hours"
              onChange={handleChange.bind(this)}
              name="hours"
            >
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
            </Form.Control>

            <Form.Label>hours</Form.Label>
          </Col>
          <Col>
            <Form.Control
              as="select"
              placeholder="hours"
              onChange={handleChange.bind(this)}
              name="minutes"
            >
              <option>15</option>
              <option>30</option>
              <option>45</option>
            </Form.Control>
            <Form.Label>minutes</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Nearest Bus Stop</Form.Label>
            <Form.Control
              rows={5}
              placeholder="Nearest Bus Stop"
              className="field"
              name="walkNearestBusStop"
              onChange={handleChange.bind(this)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Difficulty</Form.Label>
            <Form.Control
              as="select"
              name="walkDifficulty"
              onChange={handleChange.bind(this)}
            >
              <option>Easy</option>
              <option>Medium</option>
              <option>Hard</option>
            </Form.Control>
          </Col>
        </Row>
        <Row>
          <Form.Label>Additional Information</Form.Label>
        </Row>
        <Row>
          <Col>
            <Form.Control
              name="walkInfo"
              type="text"
              as="textarea"
              placeholder="Additional Info"
              className="field"
              onChange={handleChange.bind(this)}
            />
          </Col>
        </Row>
        <Row>
          <Form.Label>
            <h2>Leader Information</h2>
          </Form.Label>
        </Row>
        <Row>
          <Col>
            <Form.Label>Name</Form.Label>

            <Form.Control
              type="input"
              placeholder="Leader Name"
              required
              name="walkLeaderName"
              className="field"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="Phone"
              required
              name="leaderPhone"
              className="field"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Format: 123-456-7890
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              required
              name="walkLeaderEmail"
              className="field"
              onChange={handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              type="submit"
              className="field"
              onChange={handleChange.bind(this)}
            >
              Submit form
            </Button>
          </Col>
        </Row>
      </Form>}
    </>
  );
}
export default LeadForm;

function submitData(data) {
  axios({
    method: "post",
    url: `${process.env.REACT_APP_LEAD_API}`,
    headers: { "content-type": "application/json" },
    data: data,
  }).then((result) => {
    console.log(result);
    if (result.status === 201) {
      return true;
    } else {
      return false;
    }
  });
}
