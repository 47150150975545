import React from "react";
import { Col, Card } from "react-bootstrap";

function Box(props) {
  return (
    <Col sm={10} className="custCol p-4 m-2 d-flex justify-content-center">
      <Card>
        <Card.Title ><h2>{props.header}</h2></Card.Title>
        <Card.Body><p> {props.text}</p></Card.Body>
      </Card>
    </Col>
  );
}

export default Box;
