
import profile from '../../img/Jane_Jacobs.jpg';


function Header() {
    return (
        <header className="boxStyle">
            <div className="container-fluid">
                <div className='row'>
                    <div className='col d-sm-block d-none  w-25'>                        
                        <img className="circular-square  w-50" alt="blackcrow" src={profile} />
                    </div>
                    <div className='col w-75'>
                        <div className="row">
                            <h1>Jane's Walk Saskatoon</h1>
                        </div>
                        <div className="row">
                            <h2>Google Maps API based project</h2>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    );
}

export default Header;
