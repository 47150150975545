//import './style/custom.scss';
import LeadPage from './components/pages/LeadPage';
import Header from './components/basic/Header';
import Footer from './components/basic/Footer';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/pages/Home';
import Join from './components/pages/Join';
import List from './components/pages/List';
import Nav from './components/Nav'

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Nav />
        <main>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/listWalks" component={List} />            
            <Route path="/lead" component={LeadPage} />            
            <Route path="/join" component={Join} />         
          </Switch>
        </main>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;

