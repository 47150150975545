import { forwardRef } from "react";
import MaterialTable from "material-table";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ArrowDownward, ChevronLeft, ChevronRight } from "@material-ui/icons";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
export default function CustomTable(props) {
  const tableIcons = {
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  if (Array.isArray(props.data) && Array.isArray(props.columns)) {
    return (
      <div className="col-md-12 justify-content-center">
        <MaterialTable
          icons={tableIcons}
          data={props.data}
          detailPanel={(rowData) => {
            return (
              <div
                style={{
                  fontFamily: '"Montserrat", monospace',
                  padding: "10px",
                  backgroundColor: "#CCCBC7",
                  width: "100%",
                }}
              >
                <p>
                  <strong>Description:</strong> {rowData.walkDescription}
                </p>
                <p>
                  <strong>Start Time:</strong> {rowData.walkTime}
                </p>
                <p>
                  <strong>Difficulty:</strong> {rowData.walkDifficulty}
                </p>
                <p>
                  <strong>Additional Info:</strong> {rowData.walkInfo}
                </p>
                <p>
                  <strong>Nearest Bus Stop:</strong>{" "}
                  {rowData.walkNearestBusStop}
                </p>
                <p>
                  <strong>Walk Leader:</strong> {rowData.walkLeaderName}
                </p>
                <p>
                  {" "}
                  <strong>Map: </strong>
                  <a
                    href={
                      "https://www.google.com/maps/?q=" +
                      rowData.pointLat +
                      "," +
                      rowData.pointLong
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Maps
                  </a>
                </p>
              </div>
            );
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          columns={props.columns}
          title={props.title}
          options={{
            pageSizeOptions: [5, 10, 25, 50, 100],
            headerStyle: {
              backgroundColor: "#031219",
              color: "#FFF",
              fontFamily: '"Montserrat", monospace',
              textTransform: "capitalize",
            },
            grouping: true,
            search: true,
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="col-md-12">
        <Loader type="Bars" color="#6B1107" height={100} width={100} />
      </div>
    );
  }
}
