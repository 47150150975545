import {Col, Container, Row} from 'react-bootstrap';
import Box from '../basic/Box';

function Home() {
  return (
    <>
      <Container fluid className="overflow-hidden">
          <Row className="article">
            <Col></Col>
              <Box header="About" text="This is a project previously done in Wordpress, 
              updated to use React.js as a frontend and PHP rest api as the backend." />

              <Col></Col>
          </Row>
      </Container>
    </>
  );
}

export default Home;
