import { Container, Row, Col } from "react-bootstrap";
import 'react-bootstrap/dist/react-bootstrap.min.js';
import LeadForm from "../LeadForm";

function LeadPage() {
  return (
    <Container fluid className="article">
      <Row>
      </Row>
      <Row>
        <Col>
        </Col>
        <Col  lg={8}>
          <LeadForm />
        </Col>
        <Col >
        </Col>
      </Row>
    </Container>
  );
}

export default LeadPage;
