import React from "react";
import MarkerInfoWindow from "../map/GoogleMapMarked";

function Join() {
  return (
    <div className="googleMap">
      <MarkerInfoWindow />
      </div>
  );
}

export default Join;
