import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Navbar,
  Nav,
  Button,
} from "react-bootstrap";
import ResultTable from "../ResultTable";

function List() {
  const [data, setData] = useState();
  const [past, setPast] = useState(false);
  const columns = [
    { field: "pointText", title: "Title" },
    { field: "walkDate", title: "Date", type: "date" },
    {
      field: "walkAddress",
      title: "Address",
      render: (rowData) => (
        <a
          href={
            "https://www.google.com/maps/?q=" +
            rowData.pointLat +
            "," +
            rowData.pointLong
          }
          target="_blank"
          rel="noreferrer"
        >
          View</a>
      ),
    },
    { field: "walkDifficulty", title: "Difficulty" },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    var url = "";
    if (past) {
      url = process.env.REACT_APP_READ_MARKER_PAST;
    } else {
      url = process.env.REACT_APP_READ_MARKER;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        //set data
        setData(data.records);
      });
    setPast(!past);
  };

  return (
    <>
      <Row>
        <Col>
          <Navbar collapseOnSelect expand="lg">
            <Nav>
              <Button size="lg" variant="dark" disabled={past} onClick={getData}>
                Current
              </Button>
              <Button size="lg" variant="dark" disabled={!past} onClick={getData}>
                Past
              </Button>
            </Nav>
          </Navbar>
        </Col>
      </Row>
      <Row>
        <Col p-10>
          {!isEmpty(columns) && !isEmpty(data) && (
            <ResultTable data={data} columns={columns} title="Walks" />
          )}
        </Col>
      </Row>
    </>
  );
}

function isEmpty(strIn) {
  if (strIn === undefined) {
    return true;
  } else if (strIn == null) {
    return true;
  } else if (strIn === "") {
    return true;
  } else {
    return false;
  }
}

export default List;
