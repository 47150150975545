import React, { Component, useState } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import isEmpty from "lodash.isempty";
// examples:
import GoogleMap from "./GoogleMap";

// consts: [34.0522, -118.2437]
import CENTER from "../const/saskatoon_center";

// InfoWindow component
const InfoWindow = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: "relative",
    bottom: 150,
    left: "-45px",
    width: "30vw",
    backgroundColor: "white",
    wordWrap: "break-word",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    padding: "1vw",
    fontSize: "1.5vw",
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div></div>
      <p>
        <strong> {place.pointText} </strong>
      </p>
      <p>
        <strong>Date:</strong> {place.walkDate} <strong>Time:</strong> {place.walkTime}{" "}
      </p>
      <p>
        <strong>Info:</strong> {place.walkInfo}{" "}
      </p>
    </div>
  );
};

// Marker component
const Marker = ({ place, show }) => {
  const markerStyle = {
    border: "1px solid white",
    borderRadius: "50%",
    height: 15,
    width: 15,
    backgroundColor: show ? "orange" : "black",
    cursor: "pointer",
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle} />
      {show && <InfoWindow place={place} />}
    </>
  );
};

class MarkerInfoWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_READ_MARKER)
      .then((response) => response.json())
      .then((data) => {
        //add show variable to data results
        data.records.forEach((result) => {
          result.show = false; // eslint-disable-line no-param-reassign
          result.lat = result.pointLat;
          result.lng = result.pointLong;
        });
        //set data
        this.setState({ places: data.records });
      });
  }

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key) => {
    var tempPlaces = this.state.places;
    this.onClickCallBack();

    //show new window
    const index = tempPlaces.findIndex((e) => e.ID === key);
    tempPlaces[index].show = !tempPlaces[index].show; // eslint-disable-line no-param-reassign

    /* (state) => {
      const index = state.places.findIndex((e) => e.ID === key);
      state.places[index].show = !state.places[index].show; // eslint-disable-line no-param-reassign
      return { places: state.places };
     */
    this.setState({ places: tempPlaces });
  };

  onClickCallBack = () => {
    var tempPlaces = this.state.places;
    //hide old
    var i;
    for (i = 0; i < tempPlaces.length; i++) {
      tempPlaces[i].show = false;
    }
    this.setState({ places: tempPlaces });
  };

  render() {
    const { places } = this.state;
    const mapStyle = {
      width: "100%",
      height: "100%",
      minHeight: "500px",
      padding: "0.5vw",
      position: "relative",
    };
    return (
      <>
        {!isEmpty(places) && (
          <Container>
            <GoogleMap
              defaultZoom={10}
              defaultCenter={CENTER}
              bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
              className="googleMaps"
              style={mapStyle}
              onChildClick={this.onChildClickCallback}
              onClick={this.onClickCallBack}
            >
              {places.map((place) => (
                <Marker
                  key={place.ID}
                  lat={place.lat}
                  lng={place.lng}
                  show={place.show}
                  place={place}
                />
              ))}
            </GoogleMap>
          </Container>
        )}
      </>
    );
  }
}
export default MarkerInfoWindow;

InfoWindow.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};
